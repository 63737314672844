import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/shopify-generic/Templates/SearchPage.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTilessubtitles from 'Stores/_default-store/Templates/facetTiles/subtitles.rt'
import { FacetPanel } from 'Components'
import { FacetBar } from 'Components'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/_default-store/Templates/fitmentSearch/singleVehicleGarage.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/shopify-generic/Templates/fitmentSearch/fitmentTable.rt'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import TemplatefacetTilesalphabeticalContainer from 'Stores/_default-store/Templates/facetTiles/alphabeticalContainer.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/_default-store/Templates/product/relatedItems.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialogVehicleSpecs from 'Stores/shopify-generic/Templates/FacetDialogVehicleSpecs.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { RequestPanel } from 'Components'
import TemplateHomeWheelTireBar from 'Stores/_default-store/Templates/HomeWheelTireBar.rt'
import { WheelTireVehicleInfo } from 'Components'

export const compMap = {
  SearchPage,
FacetTiles,
FacetPanel,
FacetBar,
SearchResult,
SearchHeader,
SearchBoxDialogButton,
VehicleWidget,
Garage,
SingleVehicleGarage,
VerifyFitment,
FitmentTable,
RelatedItems,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog,
RequestPanel,
WheelTireVehicleInfo
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'facetTiles/subtitles': TemplatefacetTilessubtitles,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'facetTiles/alphabeticalContainer': TemplatefacetTilesalphabeticalContainer,
'product/relatedItems': TemplateproductrelatedItems,
'FacetDialogVehicleSpecs': TemplateFacetDialogVehicleSpecs,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'HomeWheelTireBar': TemplateHomeWheelTireBar
};